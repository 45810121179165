import React from 'react';
import { State, Context, BasicInformation } from './types';

export const defaultState: State = {
	currentStep: 0,
	organizationId: '',
	organizations: [],
	companyId: '',
	sectorId: '',
	lineId: '',
	workstation: '',
	analyst: '',
	fileName: '',
	createdAt: [],
	collectionDate: [],
	selectedPEA: [],
	data: {
		basicInformation: {} as BasicInformation,
		conclusion: '',
		pea_list: []
	},
	queryParams: {
		companyId: '',
		organizationId: '',
		clearFilter: false
	}
};

const defaultContext: Context = {
	...defaultState,
	nextStep: () => {},
	prevStep: () => {},
	setData: () => {},
	setOrganizations: () => {},
	setOrganizationId: () => {},
	setCompanyId: () => {},
	setSectorId: () => {},
	setLineId: () => {},
	setCollectionDate: () => {},
	setCreatedAt: () => {},
	setWorkstation: () => {},
	setAnalyst: () => {},
	setFileName: () => {},
	setSelectedPEA: () => {},
	setQueryParams: () => {}
};

export const MergeAEPContext = React.createContext<Context>(defaultContext);
