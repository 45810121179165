import styled from 'styled-components';

export const Container = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%); /* for IE 9 */
	-webkit-transform: translate(-50%, -50%); /* for Safari */

	/* optional size in px or %: */
	width: 400px;
	/* height: 400px; */
	height: auto;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);

	button {
		width: 100%;
	}

	p {
		color: rgb(204, 204, 204);
		text-align: center;
		margin-top: 16px;
		font-size: 12px;
		display: flex;
		justify-content: space-between;
	}
`;

export const ImageBox = styled.div`
	text-align: center;
	cursor: pointer;
	margin-bottom: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 60%;
		padding-top: 3%;
		padding-bottom: 5%;
	}

	span {
		vertical-align: text-bottom;
		font-size: 16px;
		text-transform: uppercase;
		display: inline-block;
		font-weight: 700;
	}
`;

export const ContainerAlerts = styled.div`
	padding: 0 0 15px 0;
`;

export const Link = styled.a`
	display: flex;
	justify-content: center;
`;
