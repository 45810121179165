import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, message, Select } from 'antd';
import { useEvaluators } from '@/hooks/v1';
import { MergeAEPContext } from '../../context';

export const Analyst: React.FC = () => {
	const { organizationId, companyId, setAnalyst, queryParams, setQueryParams } = useContext(MergeAEPContext);

	const { isLoading, data, isError } = useEvaluators({
		organization_id: organizationId,
		company_id: companyId
	});

	function handleOnChange(analyst: string): void {
		setAnalyst(analyst);
		setQueryParams({ ...queryParams, analyst: analyst });
	}

	if (isError) {
		message.error(I18n.get('Failed to search for analysts'));
	}

	return (
		<Form.Item name="evaluator_id">
			<Select
				loading={isLoading}
				onChange={handleOnChange}
				placeholder={I18n.get('Analyst')}
				disabled={!organizationId || !companyId}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
};
