import React, { useContext, useEffect } from 'react';

import { I18n } from '@aws-amplify/core';
import { Form, Select } from 'antd';
import { MergeAEPContext } from '../../context';
import hooks from '../../hooks';

const { useWatch, useFormInstance } = Form;
const { useGetCompanies } = hooks;

export function Company() {
	const form = useFormInstance();

	const {
		organizationId,
		setQueryParams,
		setWorkstation,
		setCompanyId,
		queryParams,
		setSectorId,
		setFileName,
		setAnalyst,
		setLineId
	} = useContext(MergeAEPContext);

	const {
		data: companiesList,
		isLoading: gettingCompanies,
		isError: companiesError,
		refetch
	} = useGetCompanies(organizationId);

	const company = useWatch('companyId');

	useEffect(() => {
		!company && refetch();
	}, [company]);

	function handleOnClearHierarchy(): void {
		setWorkstation('');
		setSectorId('');
		setFileName('');
		setAnalyst('');
		setLineId('');
	}

	function handleCompanyChange(companyId: string) {
		setCompanyId(companyId);
		handleOnClearHierarchy();
		form.resetFields(['sectorId', 'lineId', 'workstation', 'evaluator_id', 'fileName']);
		setQueryParams({ ...queryParams, companyId });
	}

	return (
		<Form.Item name="companyId">
			<Select
				showSearch
				loading={gettingCompanies}
				placeholder={I18n.get('Industrial Site')}
				onChange={(value) => handleCompanyChange(value)}
				disabled={companiesList.length === 0 || companiesError}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
				options={companiesList.map(({ id, name }) => ({ label: name, value: id }))}
			/>
		</Form.Item>
	);
}
