import React, { useContext } from 'react';
import { Form, message, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { MergeAEPContext } from '../../context';
import { useWorkstations } from '@/hooks/v1';

export const Workstation: React.FC = () => {
	const { organizationId, companyId, lineId, setWorkstation, queryParams, setQueryParams } = useContext(MergeAEPContext);

	const { data, isLoading, isError } = useWorkstations({organization_id: organizationId, company_id: companyId, line_id: lineId });


	function handleOnChange(workstation: string) {
		setWorkstation(workstation);
		setQueryParams({ ...queryParams,workstation });
	}

	if (isError) {
		message.error(I18n.get('Failed to fetch workstations'));
	}

	return (
		<Form.Item name="workstation">
			<Select
				showSearch
				disabled={!lineId}
				loading={isLoading}
				onChange={handleOnChange}
				placeholder={I18n.get('Workstation')}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
			/>
		</Form.Item>
	);
};
