import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Divider } from 'antd';

import { FieldsComparison } from './FieldsComparison';
import { StepKeysHistory } from '@/hooks/useGetCustomReportReviewHistory';
import { GrayCircle } from './styles';

interface ReviewFieldsProps {
	stepKeysHistory: StepKeysHistory[];
}

export const ReviewFields: React.FC<ReviewFieldsProps> = ({ stepKeysHistory }) => {
	return (
		<Col xs={24}>
			{stepKeysHistory.map((stepKey) => {
				return (
					<>
						<Row gutter={[0, 0]} justify="space-between" align="middle">
							<Col xs={24}>
								<Row gutter={[0, 0]} align="middle">
									<Col span={1}>
										<GrayCircle />
									</Col>
									<Col span={23}>
										<span style={{ margin: '0 0 0 1rem', fontWeight: 600, fontSize: '1rem' }}>
											{I18n.get(stepKey.description)}
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col span={1} style={{ minHeight: '100%' }}>
								<Divider
									type="vertical"
									style={{
										margin: '0 0 0 0.7rem',
										borderLeft: '4px solid #e6e8e8',
										height: '100%'
									}}
								/>
							</Col>

							<Col span={21} style={{ marginLeft: '0.8rem' }}>
								<FieldsComparison stepKeyHistory={stepKey} fields={stepKey.changed_fields} />
							</Col>
						</Row>
					</>
				);
			})}
			{!stepKeysHistory?.length && (
				<Col span={10} style={{ marginTop: '1rem' }}>
					<span style={{ margin: '0 0 0 1rem', fontWeight: 600, fontSize: '1rem' }}>
						{I18n.get('No fields were changed')}
					</span>
				</Col>
			)}
		</Col>
	);
};
