import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import { Row, Col, Dropdown, Grid, Image, Button, Tooltip } from 'antd';
import Icon, { DeleteOutlined, DownloadOutlined, MenuOutlined } from '@ant-design/icons';

import { useApplicationContext } from '@/context/v1/Application/context';
import { useDownloadFile, useDownloadPdf } from './hooks';
import { JdsD92Icon } from '@/assets/icons/jds-d92-icon';
import type { JdsD92List } from './hooks/types/response';
import type { SelectedReport } from './types';
import * as S from './styles';

interface ActionsProps {
	rowData: JdsD92List;
	report_id?: string;
	onDeleteActionClick: (report: SelectedReport) => void;
}

const DOWNLOAD_ICON = 'https://kinebot-statics.s3.amazonaws.com/download-video-link.svg';

export function Actions({ rowData, onDeleteActionClick, report_id }: ActionsProps) {
	const history = useHistory();
	const breakpoints = Grid.useBreakpoint();
	const { organization, company } = useApplicationContext();
	const { mutateAsync: downloadPdf, isLoading: downloadingPDF } = useDownloadPdf();
	const { mutateAsync: downloadFile, isLoading: downloadingFile } = useDownloadFile();

	const fontSize = breakpoints.xxl ? '25px' : '15px';
	const btnSize = breakpoints.xxl ? 'large' : 'small';
	const browserLanguage = window.navigator.language ?? 'en-US';

	function handleEditReport(data: JdsD92List): void {
		history.push(`/custom-reports/jds-d92/report/${data.file_id}`);
	}

	async function handleDownloadPdf(data: JdsD92List): Promise<void> {
		await downloadPdf({
			organization_id: organization?.id,
			company_id: company?.id,
			locale: browserLanguage,
			file_id: data.file_id,
			custom_report_result_id: data.id,
			report_id,
			selected_pdf_sections: [
				'not_evaluated',
				'back_compressive_force_estimation',
				'niosh',
				'liberty_mutual',
				'kim_mho',
				'reba',
				'action_plans'
			]
		});
	}

	async function handleDownloadFile() {
		await downloadFile({
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: rowData.file_id,
			original_name: rowData.file?.original_name
		});
	}

	const items = [
		{
			key: 1,
			icon: <JdsD92Icon width={fontSize} height={fontSize} />,
			label: (
				<Button type="link" onClick={() => handleEditReport(rowData)}>
					{I18n.get('Edit')}
				</Button>
			)
		},
		{
			key: 2,
			icon: (
				<Image src={DOWNLOAD_ICON} preview={false} style={{ width: '12px' }} alt={I18n.get('Download video')} />
			),
			label: (
				<Button type="link" onClick={() => handleDownloadFile()}>
					{I18n.get('Download file')}
				</Button>
			)
		},
		{
			key: 4,
			icon: <DownloadOutlined />,
			label: (
				<Button
					type="link"
					loading={downloadingPDF}
					disabled={downloadingPDF}
					onClick={() => handleDownloadPdf(rowData)}
				>
					{I18n.get('Download')}
				</Button>
			)
		},
		{
			key: 5,
			danger: true,
			icon: <DeleteOutlined />,
			label: (
				<S.CustomButton>
					<Button
						type="link"
						onClick={() =>
							onDeleteActionClick({
								id: rowData.id,
								report_name: rowData.name
							})
						}
					>
						{I18n.get('Delete')}
					</Button>
				</S.CustomButton>
			)
		}
	];

	return (
		<Row align="middle" justify="center">
			<S.ActionsInline xs={24}>
				<Row align="middle" justify="center" gutter={[10, 0]}>
					<Col span={4}>
						<Button
							type="link"
							size={btnSize}
							onClick={() => handleEditReport(rowData)}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Edit')}>
									<Icon component={JdsD92Icon as React.FC} style={{ fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							type="link"
							size="large"
							loading={downloadingFile}
							disabled={downloadingFile}
							onClick={() => handleDownloadFile()}
							icon={
								<Tooltip title={I18n.get('Download')}>
									<Image
										preview={false}
										src={DOWNLOAD_ICON}
										style={{ width: '1em' }}
										alt={I18n.get('Download video')}
									/>
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							type="link"
							size={btnSize}
							loading={downloadingPDF}
							disabled={downloadingPDF}
							onClick={() => handleDownloadPdf(rowData)}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Download')}>
									<DownloadOutlined style={{ fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
					<Col span={4}>
						<Button
							onClick={() => {
								onDeleteActionClick({
									id: rowData.id,
									report_name: rowData.name
								});
							}}
							type="link"
							size={btnSize}
							icon={
								<Tooltip overlayStyle={{ position: 'fixed' }} title={I18n.get('Delete')}>
									<DeleteOutlined style={{ color: '#E74150', fontSize }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
			</S.ActionsInline>
			<S.ActionsMenu xs={2}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<Button type="link" icon={<MenuOutlined />} />
				</Dropdown>
			</S.ActionsMenu>
		</Row>
	);
}
