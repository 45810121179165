import React from 'react';
import moment from 'moment';
import { isNumber } from 'lodash';
import { InputNumber } from 'antd';
import { I18n } from '@aws-amplify/core';
import { WarningOutlined } from '@ant-design/icons';
import { getDecimalSeparator } from '@/utils/getDecimalSeparator';
import {
	DurationLabel,
	GridContainer,
	LeftDuration,
	numberInputStyle,
	PercentageDisplay,
	RightDuration,
	SuffixDuration,
	Warning
} from './styles';

export const ExertionDuration = ({ durationPercentage, isPercentageValid, onChangeFactors }) => {
	const locale = moment.locale();
	return (
		<GridContainer>
			<DurationLabel>{I18n.get('Average single exertion time')}</DurationLabel>
			<LeftDuration
				name="input_left_exertion_duration"
				style={{ margin: 0 }}
				extra={
					durationPercentage.left && (
						<PercentageDisplay percentage={durationPercentage.left}>
							<>
								{new Intl.NumberFormat(locale).format((durationPercentage.left * 100).toFixed(2)) ?? 0}%
							</>
						</PercentageDisplay>
					)
				}
				rules={[
					{
						required: true,
						message: false,
						validator: (_, value) => {
							if (!isNumber(value)) {
								return Promise.reject(new Error(I18n.get('Invalid percentage')));
							}

							if (isPercentageValid.left) {
								return Promise.resolve();
							}

							return Promise.reject(new Error(I18n.get('Invalid percentage')));
						}
					}
				]}
			>
				<InputNumber
					min={0.0}
					max={999}
					step={0.01}
					placeholder="0.0"
					style={numberInputStyle}
					onChange={onChangeFactors}
					decimalSeparator={getDecimalSeparator()}
				/>
			</LeftDuration>
			<RightDuration
				name="input_right_exertion_duration"
				style={{ margin: 0 }}
				extra={
					durationPercentage.right && (
						<PercentageDisplay percentage={durationPercentage.right}>
							<>
								{new Intl.NumberFormat(locale).format((durationPercentage.right * 100).toFixed(2)) ?? 0}
								%
							</>
						</PercentageDisplay>
					)
				}
				rules={[
					{
						required: true,
						message: false,
						validator: (_, value) => {
							if (!isNumber(value)) {
								return Promise.reject(new Error(I18n.get('Invalid percentage')));
							}

							if (isPercentageValid.right) {
								return Promise.resolve();
							}

							return Promise.reject(new Error(I18n.get('Invalid percentage')));
						}
					}
				]}
			>
				<InputNumber
					min={0.0}
					max={999}
					step={0.01}
					placeholder="0.0"
					style={numberInputStyle}
					onChange={onChangeFactors}
					decimalSeparator={getDecimalSeparator()}
				/>
			</RightDuration>
			<SuffixDuration>{I18n.get('seconds')}</SuffixDuration>
			{durationPercentage.left > 1 || durationPercentage.right > 1 ? (
				<Warning>
					<WarningOutlined style={{ marginRight: '5px' }} />
					{I18n.get(
						'(total exertions * single exertion time) must be less than or equal to the total observation time'
					)}
				</Warning>
			) : null}
		</GridContainer>
	);
};
