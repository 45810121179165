import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/custom-report';

interface GetEwaCustomReportResultsParams {
	organization_id: string;
	company_id: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	evaluator_id?: string;
	collection_date_start?: Date;
	collection_date_end?: Date;
	created_at_start?: Date;
	created_at_end?: Date;
	file_name?: string;
	offset: number;
	limit: number;
}

export const useGetEwaCustomReportResults = (params: GetEwaCustomReportResultsParams) => {
	return useQuery({
		queryKey: [QUERY_KEYS.GET_EWA_CUSTOM_REPORT_RESULTS, params],
		queryFn: async () => Service.findAllEwa(params),
		enabled: !!params.organization_id
	});
};
