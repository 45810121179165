import React, { useContext, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form } from 'antd';
import moment from 'moment';

import { useGetEwaCustomReportResults } from '@/hooks/useGetEwaCustomReportResults';
import { EwaCustomReportResult, SelectedPEA } from '../types';
import { MergeAEPContext } from '../context';
import { TableList } from './TableList';
import { TextCustom } from './styles';
import { Filter } from './Filter';
import { PaginationCustom } from '@/components/Pagination';

interface ChecklistProps {
	onClearFilter: (organizationId?: string) => void;
}

export const Checklist: React.FC<ChecklistProps> = ({ onClearFilter }) => {
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(10);

	const {
		queryParams,
		organizationId,
		data: { pea_list },
		setQueryParams
	} = useContext(MergeAEPContext);

	const { data, isLoading, refetch } = useGetEwaCustomReportResults({
		organization_id: organizationId,
		company_id: queryParams.companyId,
		sector_id: queryParams.sectorId,
		line_id: queryParams.lineId,
		workstation_id: queryParams.workstation,
		collection_date_start: queryParams.collectionDate?.[0] ? new Date(queryParams.collectionDate?.[0]) : undefined,
		collection_date_end: queryParams.collectionDate?.[1] ? new Date(queryParams.collectionDate?.[1]) : undefined,
		created_at_start: queryParams.createdAt?.[0] ? new Date(queryParams.createdAt?.[0]) : undefined,
		created_at_end: queryParams.createdAt?.[1] ? new Date(queryParams.createdAt?.[1]) : undefined,
		evaluator_id: queryParams.analyst,
		file_name: queryParams.fileName,
		offset: (page - 1) * limit,
		limit
	});

	function handleOnChangePagination(page: number, pageSize: number): void {
		setLimit(pageSize);
		setPage(page);
	}

	function handleOnChangeRowsPerPage(limit: number): void {
		setLimit(limit);
	}

	function handleFilter() {
		const element = document.getElementById('tableList');
		element?.scrollIntoView({ behavior: 'smooth' });
		setQueryParams({ ...queryParams, clearFilter: false });
		refetch();
	}

	function mountingPEA(report: EwaCustomReportResult): SelectedPEA {
		const { id, collection_date, created_at, analyst_name, file_name, result } = report;
		const dateOfCollection = moment(collection_date).format('DD-MM-YYYY');
		const createdAt = moment(created_at).format('DD-MM-YYYY');
		return {
			id,
			key: id,
			dateOfCollection,
			analystName: analyst_name,
			fileName: file_name,
			score: result,
			createdAt
		};
	}

	return (
		<Col lg={24} xl={20} xxl={16}>
			<Row gutter={[10, 10]}>
				<Col span={24}>
					<Filter onFilter={handleFilter} onClearFilter={onClearFilter} />
				</Col>
				<Col>
					<TextCustom>
						{
							"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's."
						}
					</TextCustom>
				</Col>
				<Col span={24} id="tableList">
					<Form.Item
						name="pea_list"
						rules={[
							() => ({
								validator() {
									return !pea_list || pea_list.length === 0 || !pea_list[0]
										? Promise.reject(I18n.get('Select a file'))
										: Promise.resolve();
								}
							})
						]}
					>
						<TableList isLoading={isLoading} peaList={data?.rows.map(mountingPEA)} />
						<PaginationCustom
							current={page}
							pageSize={limit}
							showSizeChanger={false}
							total={data?.count || 0}
							onChange={handleOnChangePagination}
							onRowsPerPageChange={handleOnChangeRowsPerPage}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Col>
	);
};
