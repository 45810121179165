import React, { useContext } from 'react';
import { DatePicker, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { MergeAEPContext } from '../../context';

export function CollectionDate() {
	const { organizationId, companyId, queryParams, setCollectionDate, setQueryParams } = useContext(MergeAEPContext);

	function handleCollectionDateChange(date: any, dateString: string[]) {
		setCollectionDate(dateString);
		setQueryParams({
			...queryParams,
			collectionDate: date ? [date?.[0]?.toISOString(), date?.[1]?.toISOString()] : undefined
		});
	}

	return (
		<Form.Item name="collectionDate" label={I18n.get('Collection date')}>
			<DatePicker.RangePicker
				format="L"
				style={{ width: '100%' }}
				disabled={!organizationId || !companyId}
				onChange={handleCollectionDateChange}
				showTime={{ format: 'HH:mm:00' }}
			/>
		</Form.Item>
	);
}
