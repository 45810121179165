import React, { useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { DatePicker, Form } from 'antd';

import { MergeAEPContext } from '../../context';

export function CreatedAt() {
	const { organizationId, companyId, setCreatedAt, queryParams, setQueryParams } = useContext(MergeAEPContext);

	function handleOnChange(createdAt: string[], date: any) {
		setCreatedAt(createdAt);
		setQueryParams({
			...queryParams,
			createdAt: date ? [date?.[0]?.toISOString(), date?.[1]?.toISOString()] : undefined
		});
	}

	return (
		<Form.Item label={I18n.get('Created At')} name="createdAt">
			<DatePicker.RangePicker
				onChange={(date, dateString) => handleOnChange(dateString, date)}
				disabled={!organizationId || !companyId}
				showTime={{ format: 'HH:mm:00' }}
				style={{ width: '100%' }}
				format="L"
			/>
		</Form.Item>
	);
};
