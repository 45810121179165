import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Skeleton, Table } from 'antd';

import { Title } from '@/components/Typography';

import { BeraResultAnalyzedFiles } from '../types';
import { CustomTable } from './styles';

interface TasksAnalyzedProps {
	isLoading: boolean;
	tasksAnalyzed: BeraResultAnalyzedFiles[];
}

export const TasksAnalyzed: React.FC<TasksAnalyzedProps> = ({ isLoading, tasksAnalyzed }) => {
	const columns: ColumnsType<BeraResultAnalyzedFiles> = [
		{
			title: I18n.get('TLID'),
			dataIndex: 'task_name',
			key: 'task_name',
			ellipsis: true,
			align: 'center'
		},
		{
			title: I18n.get('File'),
			dataIndex: 'file_name',
			key: 'file_name',
			ellipsis: true,
			align: 'center',
			responsive: ['md']
		},
		{
			title: <span>{I18n.get('Time')} (min)</span>,
			dataIndex: 'total_time',
			key: 'total_time',
			ellipsis: true,
			align: 'center',
			responsive: ['sm']
		}
	];

	return (
		<Col>
			<Row>
				<Title level={5}>Tasks analyzed</Title>
			</Row>
			<Row justify="center">
				{isLoading ? (
					<Col span={24}>
						<Skeleton active />
					</Col>
				) : (
					<CustomTable span={22}>
						<Table rowKey="id" pagination={false} dataSource={tasksAnalyzed} columns={columns} />
					</CustomTable>
				)}
			</Row>
		</Col>
	);
};
