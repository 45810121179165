import React from 'react';
import { riskLevels } from '@/utils/riskRange';
import { Text } from '@/components/Typography';
import { RiskCard, RiskCardBody, RiskCardHeader } from './styles';

interface RiskResultProps {
	score: number;
	result: number;
}

export default function RiskResult({ result, score }: Readonly<RiskResultProps>) {
	return (
		<RiskCard>
			<RiskCardHeader result={result}>
				<Text>{riskLevels[result] || 'Not Assigned'}</Text>
			</RiskCardHeader>
			<RiskCardBody>
				<Text>RPN: {score}</Text>
			</RiskCardBody>
		</RiskCard>
	);
}
