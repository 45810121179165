import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Title } from './Title';
import { ApplyTool } from '../ApplyTool';
import { InfoTooltip } from './InfoTooltip';
import { Text } from '@/components/Typography';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { ITasksProps, ToolType } from './types';
import { Description } from './styles';

export const ToolCard: React.FC<ITasksProps> = ({
	isActive = false,
	reportSelected = '',
	title = '',
	subTitle,
	fileId = '',
	type,
	toolTip,
	toolTipPosition = 'right'
}: ITasksProps) => {
	const [, setName] = useLocalStorage(reportSelected, true);
	const description: string = isActive ? 'Tool already set' : 'Tool not set yet';

	const handleOperation = (toolName: ToolType): void => {
		const reportsInFullView: ToolType[] = [
			'niosh',
			'strain-index',
			'reba',
			'kim-mho',
			'kim-pp',
			'liberty-mutual',
			'back-compressive-force-estimation'
		];
		let url: string = `/reporting`;

		if (reportsInFullView.includes(toolName)) {
			url = `/reporting/${toolName}/${fileId}`;
		}

		window.open(url, '_blank');
	};

	const addSettings = (toolName: ToolType): void => {
		handleOperation(toolName);
		setName(true);
	};

	return (
		<Row>
			<Col span={24} style={{ padding: 0 }}>
				<Title />
			</Col>
			{toolTip && toolTipPosition === 'top' && (
				<div>
					<InfoCircleOutlined style={{ fontSize: '0.7rem', marginRight: '0.3rem' }} />
					<Text>{toolTip}</Text>
				</div>
			)}
			<Col span={24} style={{ display: 'flex', alignItems: 'flex-start' }}>
				<ApplyTool type={type ?? 'toTop'} onClick={() => addSettings(reportSelected)} isActive={isActive}>
					<Text>{subTitle ? title : 'Load surveys'}</Text>
					<Text type="secondary">{subTitle ?? title}</Text>
				</ApplyTool>
				{toolTip && toolTipPosition === 'right' && (
					<div>
						<InfoTooltip text={toolTip} />
					</div>
				)}
			</Col>
			<Col span={24}>
				<Description isactive={isActive}>{I18n.get(description)}</Description>
			</Col>
		</Row>
	);
};
