import React from 'react';
import moment from 'moment';
import { Col, Grid } from 'antd';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import { Chart as ChartJS, LinearScale, BarElement, Tooltip } from 'chart.js';

import { Title } from '@/components/Typography';
import { SumRpn } from '@/hooks/useGetCustomReportReviewHistory';

ChartJS.register(LinearScale, BarElement, Tooltip);

type Props = {
	data: SumRpn[];
};

export function SumRPN({ data }: Props) {
	const dataConfig = {
		labels: data.map((m) => [
			m.name
				.split(' ')
				.map((m) => I18n.get(m))
				.join(' '),
			moment(m.created_at).format('L')
		]),
		datasets: [
			{
				label: '',
				backgroundColor: '#2F54EB',
				data: data.map((m) => m.rpn),
				barThickness: 79
			}
		]
	};

	const breakpoints = Grid.useBreakpoint();

	return (
		<>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Title level={3} align="center">
					{I18n.get('Sum of RPN')}
				</Title>
			</Col>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Bar
					options={{
						responsive: true,
						plugins: {
							tooltip: {
								yAlign: 'center',
								xAlign: 'center'
							},
							legend: {
								display: false
							},
							title: {
								display: true,
								align: 'center',
								text: I18n.get('Sum of RPN'),
								position: 'left',
								color: '#000',
								font: {
									size: 16,
									weight: '500'
								}
							},
							datalabels: {
								display: false
							}
						},
						scales: {
							x: {
								ticks: {
									maxRotation: 90,
									minRotation: breakpoints.xl ? 0 : 90,
									font: {
										size: 14,
										weight: 'medium'
									},
									color: '#000'
								}
							}
						}
					}}
					data={dataConfig}
				/>
			</Col>
			<Col xs={24} style={{ marginTop: '1rem' }}>
				<Title level={5} style={{ fontWeight: '500' }} align="center">
					{I18n.get('Version')}
				</Title>
			</Col>
		</>
	);
}
