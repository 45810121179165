import React from 'react';
import { Row, Col } from 'antd';

import { SummaryResult, TaskNameMapper } from '../../../hooks/types/response';
import { LiftLowerSummary } from './LiftLowerSummary';
import { PushPullSummary } from './PushPullSummary';
import { CarrySummary } from './CarrySummary';

interface SummaryProps {
	report_name: string;
	summary: SummaryResult;
	task_name: TaskNameMapper;
}

export const Summary: React.FC<SummaryProps> = ({ summary, task_name }) => {
	const taskToSummaryComponentMapper = {
		[TaskNameMapper.Lift]: <LiftLowerSummary summary={summary} task_name={task_name} />,
		[TaskNameMapper.Lower]: <LiftLowerSummary summary={summary} task_name={task_name} />,
		[TaskNameMapper.Push]: <PushPullSummary summary={summary} task_name={task_name} />,
		[TaskNameMapper.Pull]: <PushPullSummary summary={summary} task_name={task_name} />,
		[TaskNameMapper.Carry]: <CarrySummary summary={summary} task_name={task_name} />
	};

	return (
		<Row justify="start" gutter={[8, 8]}>
			<Col xs={24}>{taskToSummaryComponentMapper[task_name]}</Col>
		</Row>
	);
};
