import React, { useContext } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { I18n } from '@aws-amplify/core';
import { Col, Table } from 'antd';

import { MergeAEPContext } from '../context';
import { Spinner } from '@/components/Spinner';
import { useGetEwaCustomReportResults } from '@/hooks/useGetEwaCustomReportResults';

type TableDataType = {
	id: string;
	file_name: string;
	analyst: string;
	score: number;
};

export const TableList = () => {
	const {
		queryParams,
		organizationId,
		data: { pea_list }
	} = useContext(MergeAEPContext);

	const { isLoading, data, isError } = useGetEwaCustomReportResults({
		organization_id: organizationId,
		company_id: queryParams.companyId,
		sector_id: queryParams.sectorId,
		line_id: queryParams.lineId,
		workstation_id: queryParams.workstation,
		collection_date_start: queryParams.collectionDate?.[0] ? new Date(queryParams.collectionDate?.[0]) : undefined,
		collection_date_end: queryParams.collectionDate?.[1] ? new Date(queryParams.collectionDate?.[1]) : undefined,
		created_at_start: queryParams.createdAt?.[0] ? new Date(queryParams.createdAt?.[0]) : undefined,
		created_at_end: queryParams.createdAt?.[1] ? new Date(queryParams.createdAt?.[1]) : undefined,
		evaluator_id: queryParams.analyst,
		file_name: queryParams.fileName,
		offset: 0,
		limit: 100
	});

	const columns: ColumnsType<TableDataType> = [
		{
			title: I18n.get('Name'),
			dataIndex: 'file_name',
			key: 'key',
			align: 'center',
			ellipsis: true,
			render: (text: string) => text.split('-')[0]
		},
		{
			title: I18n.get('Analyst'),
			dataIndex: 'analyst',
			key: 'analyst',
			align: 'center',
			ellipsis: true
		},
		{
			title: I18n.get('Score'),
			dataIndex: 'score',
			key: 'score',
			align: 'center',
			ellipsis: true
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	const dataSource: TableDataType[] = data.rows
		.filter((pea) => pea_list?.some((selectedPEA) => selectedPEA === pea.id))
		.map((pea) => ({ id: pea.id, file_name: pea.file_name, analyst: pea.analyst_name, score: pea.result }));

	return (
		<Col span={24}>
			<Table rowKey="key" pagination={{ pageSize: 5 }} dataSource={dataSource} columns={columns} />
		</Col>
	);
};
