export enum QUERY_KEYS {
	GET_ORGANIZATION = 'get-organization',
	GET_CUSTOM_REPORTS = 'get-custom-reports',
	GET_MOSAIC = 'get-mosaic',
	GET_STRAIN_INDEX_RSI = 'get-strain-index-rsi',
	GET_CHECKLIST_REPORTS = 'get-checklist-reports',
	GET_DELAYED_ACTION_PLANS = 'get-delayed-action-plans',
	GET_PERCENTILE_BY_GENDER = 'percentile-by-gender',
	GET_STATUS_ACTION_PLANS = 'get-status-action-plans',
	GET_CUSTOM_REPORT_RISKS_COUNT = 'get-custom-report-risks-count',
	GET_BERA_COMPARISON = 'get-bera-comparison',
	GET_SERA_COMPARISON = 'get-sera-comparison',
	GET_BERA_SIX_TOO = 'get-bera-six-too',
	GET_SERA_MAIN_RISK = 'get-sera-main-risk',
	GET_SERA_SUM_RPN = 'get-sera-sum-rpn',
	GET_BERA_SUM_RPN = 'get-bera-sum-rpn',
	GET_REBA_EXPOSURE_SCORE = 'get-reba-exposure-score',
	GET_REBA_EXPOSURE_BODY = 'get-reba-exposure-body',
	GET_NIOSH_RISKS_COUNT = 'get-niosh-risks-count',
	GET_KIM_MHO_RISKS_COUNT = 'get-kim-mho-risks-count',
	GET_KIM_PP_RISKS_COUNT = 'get-kim-pp-risks-count',
	GET_BERA_GNS = 'get-bera-gns',
	GET_EWA_CUSTOM_REPORT_RESULTS = 'get-ewa-custom-report-results'
}
