import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Chart } from './Chart';
import { Member } from './types';
import { ColCustom } from './styles';
import type { SubStepKey } from '@/hooks';
import { DefaultSelects } from '@/components/CustomReports';
import { BodyPadding } from '@/components/ui/CollapseCheck/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useRebaAngles } from '@/components/views/PreliminaryAnalysis/hooks';
import { PreviousValues } from '@/components/CustomReports/StepKeys/PreviousValues';
import { useStepKeysContext } from '@/components/CustomReports/StepKeys/DefaultSelects/context';
import { GetCustomReportResultStepKeyDefaultDataResponse } from '@/hooks/useGetCustomReportResultStepKeyDefaultData';

const partsMapper: Record<string, Member> = {
	neck: 'neck',
	trunk: 'trunk',
	left_shoulder: 'left_upper_arm',
	right_shoulder: 'right_upper_arm',
	left_elbow: 'left_lower_arm',
	right_elbow: 'right_lower_arm'
};

interface SubStepKeyProps {
	subStepKeys?: SubStepKey[];
	gettingCustomReportDefaultData?: boolean;
	customReportDefaultData?: GetCustomReportResultStepKeyDefaultDataResponse | null;
}

export const SubStepKeys: React.FC<SubStepKeyProps> = ({
	subStepKeys,
	customReportDefaultData,
	gettingCustomReportDefaultData
}) => {
	const { organization, company } = useApplicationContext();
	const { formFieldName, stepKey, handleChangeSubStepKey, file_id } = useStepKeysContext();

	const { data: angles } = useRebaAngles(organization?.id, company?.id, file_id);

	if (!subStepKeys || subStepKeys.length === 0) {
		return <></>;
	}

	function handleChangeSubStepKeyTab(key: string) {
		handleChangeSubStepKey(subStepKeys ? subStepKeys[Number(key)].id : '');
	}

	function renderRebaChart(subStepKey: SubStepKey): React.ReactNode {
		const member = partsMapper[subStepKey?.name];
		const scoreParts = angles[member];

		return (
			<>
				<div style={{ margin: '-1rem 0rem 0rem' }}>
					<PreviousValues negative={true} />
				</div>
				<BodyPadding>
					<DefaultSelects
						disableExposure={true}
						stepKeyId={stepKey.id}
						subStepKeyId={subStepKey.id}
						formFieldName={[...formFieldName, subStepKey.id]}
						customReportDefaultData={customReportDefaultData}
						gettingCustomReportDefaultData={gettingCustomReportDefaultData}
					>
						<Row justify="start" align="middle" gutter={[0, 15]}>
							<Col span={24}>
								<Chart data={scoreParts ?? []} formFieldName={[...formFieldName, subStepKey.id]} />
							</Col>
						</Row>
					</DefaultSelects>
				</BodyPadding>
			</>
		);
	}

	return (
		<Row>
			<ColCustom span={24}>
				<Tabs
					type="card"
					defaultActiveKey="0"
					onChange={handleChangeSubStepKeyTab}
					items={subStepKeys.map((subStepKey, index) => ({
						forceRender: true,
						key: String(index),
						children: renderRebaChart(subStepKey),
						label: I18n.get(subStepKey?.description)
					}))}
				/>
			</ColCustom>
		</Row>
	);
};
