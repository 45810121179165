import React, { useState } from 'react';
import { Col, Row } from 'antd';

import { Create } from './Form';
import { Title } from '@/components/Typography';
import { defaultState, MergeAEPContext } from './context';
import { Context, OrganizationDTO, PEAData, PEAQueryParams, SelectedPEA, State } from './types';

export const MergePea: React.FC = () => {
	const [state, setState] = useState<State>(defaultState);

	function nextStep() {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep + 1 }));
	}

	function prevStep() {
		setState((prev) => ({ ...prev, currentStep: prev.currentStep - 1 }));
	}

	function setOrganizations(organizations: OrganizationDTO[]) {
		setState((prev) => ({ ...prev, organizations }));
	}

	function setOrganizationId(organizationId: string) {
		setState((prev) => ({ ...prev, organizationId }));
	}

	function setCompanyId(companyId: string) {
		setState((prev) => ({ ...prev, companyId }));
	}

	function setSectorId(sectorId: string) {
		setState((prev) => ({ ...prev, sectorId }));
	}

	function setLineId(lineId: string) {
		setState((prev) => ({ ...prev, lineId }));
	}

	function setWorkstation(workstation: string) {
		setState((prev) => ({ ...prev, workstation }));
	}

	function setAnalyst(analyst: string) {
		setState((prev) => ({ ...prev, analyst }));
	}

	function setFileName(fileName: string) {
		setState((prev) => ({ ...prev, fileName }));
	}

	function setCollectionDate(collectionDate: string[]) {
		setState((prev) => ({ ...prev, collectionDate }));
	}

	function setCreatedAt(createdAt: string[]) {
		setState((prev) => ({ ...prev, createdAt }));
	}

	function setSelectedPEA(selectedPEA: SelectedPEA[]) {
		setState((prev) => ({ ...prev, selectedPEA }));
	}

	function setData(data: PEAData) {
		setState((prev) => ({
			...prev,
			data: {
				...prev.data,
				...data
			}
		}));
	}

	function setQueryParams(changedQueryParam: PEAQueryParams) {
		setState((prev) => ({
			...prev,
			queryParams: {
				...changedQueryParam
			}
		}));
	}

	const context: Context = {
		...state,
		nextStep,
		prevStep,
		setData,
		setOrganizationId,
		setCompanyId,
		setOrganizations,
		setSectorId,
		setLineId,
		setWorkstation,
		setCollectionDate,
		setCreatedAt,
		setAnalyst,
		setFileName,
		setSelectedPEA,
		setQueryParams
	};

	return (
		<MergeAEPContext.Provider value={context}>
			<Row gutter={[0, 32]} justify="center">
				<Col span={12}>
					<Title style={{ textAlign: 'center' }} level={4}>
						Preliminary Ergonomic Analysis
					</Title>
				</Col>
				<Col span={24}>
					<Create />
				</Col>
			</Row>
		</MergeAEPContext.Provider>
	);
};
