import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Skeleton } from 'antd';
import { Title } from '@/components/Typography';

import { InformationsCycle, InformationsOrganization } from '../types';
import { Topic, Value, CustomCol } from './styles';

interface SummaryProps {
	isLoading: boolean;
	organization: InformationsOrganization;
	cycle: Omit<InformationsCycle, 'score'>;
}

export const Summary: React.FC<SummaryProps> = ({ isLoading, organization, cycle }) => {
	function getTaskTime(): string {
		const formatter = new Intl.NumberFormat(navigator.language, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2
		});

		if (cycle?.task_time_format === 'seconds') {
			const taskTime = cycle?.task_time / 60;	
			const timeFormat = taskTime < 2 ? 'minute' : 'minutes';
			return `${formatter.format(taskTime)} ${I18n.get(timeFormat)}`;
		}

		const timeFormat = cycle?.task_time < 2 ? 'minute' : 'minutes';
		return `${formatter.format(cycle?.task_time)} ${I18n.get(timeFormat)}`;
	}

	return (
		<Col span={16} offset={1}>
			<Row>
				<Title level={5}>Informations</Title>
			</Row>
			<Row align="middle" gutter={[16, 0]} style={{ marginTop: '1rem' }}>
				<Col span={12}>
					{isLoading ? (
						<Skeleton active />
					) : (
						<Row gutter={[0, 3]}>
							<CustomCol span={24}>
								<Topic>{I18n.get('Company')}:</Topic>
								<Value>{organization?.name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Industrial site')}:</Topic>
								<Value>{organization?.company_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Sector')}:</Topic>
								<Value>{organization?.sector_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Line')}:</Topic>
								<Value>{organization?.line_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Workstation')}:</Topic>
								<Value>{organization?.workstation_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Cycle')}:</Topic>
								<Value>{cycle?.cycle_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Task time')}:</Topic>
								<Value>
									{getTaskTime()}
								</Value>
							</CustomCol>
						</Row>
					)}
				</Col>
				<Col span={11}>
					{isLoading ? (
						<Skeleton active />
					) : (
						<Row gutter={[0, 3]}>
							<CustomCol span={24}>
								<Topic>{I18n.get('File')}:</Topic>
								<Value>{cycle?.file_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Evaluator')}:</Topic>
								<Value>{cycle?.evaluator}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Work center')}:</Topic>
								<Value>{cycle?.work_center}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('TLID')}:</Topic>
								<Value>{cycle?.task_name}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Operator evaluated')}:</Topic>
								<Value>{cycle?.operator_evaluated}</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Collection Date')}:</Topic>
								<Value>
									{cycle?.collection_date ? moment(cycle?.collection_date).format('L') : '-'}
								</Value>
							</CustomCol>
							<CustomCol span={24}>
								<Topic>{I18n.get('Known injuries')}:</Topic>
								<Value>
									{I18n.get(cycle?.has_known_injury ? 'Has known injury' : 'Has no injury history')}
								</Value>
							</CustomCol>
						</Row>
					)}
				</Col>
			</Row>
		</Col>
	);
};
