import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd';

import { MergeAEPContext } from '../../context';
import { I18n } from '@aws-amplify/core';
import { useLines } from '@/hooks/v1';

const { useFormInstance } = Form;

export function Line() {
	const form = useFormInstance();

	const { organizationId, companyId, sectorId, queryParams, setLineId, setQueryParams, setWorkstation } =
		useContext(MergeAEPContext);

	const { data, isLoading, isError, refetch } = useLines({
		organization_id: organizationId,
		company_id: companyId,
		sector_id: sectorId
	});

	useEffect(() => {
		sectorId.length && refetch();
	}, [organizationId, companyId, sectorId]);

	function handleOnClearHierarchy(): void {
		setWorkstation('');
	}

	function handleLineChange(lineId: string) {
		setLineId(lineId);
		handleOnClearHierarchy();
		form.resetFields(['workstation']);
		setQueryParams({ ...queryParams, lineId });
	}

	return (
		<Form.Item name="lineId">
			<Select
				showSearch
				loading={isLoading}
				onChange={handleLineChange}
				placeholder={I18n.get('Line')}
				disabled={!sectorId || isError}
				options={data?.map(({ id, name }) => ({ label: name, value: id }))}
				filterOption={(input, option) => (option?.label ?? '').includes(input)}
			/>
		</Form.Item>
	);
}
